import { ApolloError } from '@apollo/client/errors';
import { useState } from 'react';
import { FieldValues, Path, RegisterOptions, useForm } from 'react-hook-form';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import FormGroup from './FormGroup';

export type HandleSave<T> = (data: T) => Promise<any>;

export interface FormInput {
  label: string;
  type: 'text';
  validationOptions?: RegisterOptions;
  editable?: boolean;
  settable?: boolean;
}

export interface SelectInput {
  label: string;
  type: 'select';
  options: { label: string; value: string }[];
  editable?: boolean;
  settable?: boolean;
}

interface ModalFormProps<T> {
  buttonLabel: string | JSX.Element;
  buttonSize?: 'sm' | 'lg';
  editButtonLabel?: string | JSX.Element;
  inputs: { [key in Path<T>]: FormInput | SelectInput };
  handleSave: HandleSave<T>;
  editFields?: T;
}

const ModalForm = <T extends FieldValues>(
  props: ModalFormProps<T>,
): JSX.Element => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<ApolloError>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<T>({ values: props.editFields });

  const handleShow = () => {
    setShow(true);
    setError(undefined);
    if (!props.editFields) reset();
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = handleSubmit((data) => {
    setSaving(true);
    const { __typename, ...rest } = data;
    props
      .handleSave(rest as T)
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setSaving(false);
      });
  });

  return (
    <>
      <Button onClick={handleShow} size={props.buttonSize || 'lg'}>
        {props.editFields ? props.editButtonLabel || 'Edit' : props.buttonLabel}
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>{props.buttonLabel}</Modal.Header>
        <Modal.Body>
          <Form>
            {Object.entries(props.inputs).map(([k, v]) => {
              const fieldId = k as Path<T>;
              const input = v as FormInput | SelectInput;
              return (
                <FormGroup
                  key={fieldId}
                  fieldId={fieldId}
                  input={input}
                  register={register}
                  errors={errors}
                  editing={!!props.editFields}
                />
              );
            })}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {error && <Alert variant="danger">{error.message}</Alert>}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button onClick={handleSave} disabled={saving}>
            {saving ? 'Saving' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalForm;
