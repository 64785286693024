import {
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';
import { FormInput, SelectInput } from './ModalForm';

import Form from 'react-bootstrap/Form';
import FormError from './FormError';

const FormGroup = <T extends FieldValues>(props: {
  fieldId: string;
  input: FormInput | SelectInput;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  editing: boolean;
}): JSX.Element => {
  const fieldId = props.fieldId as Path<T>;
  const input = props.input as FormInput | SelectInput;
  const settable = input.settable ?? true;
  const disable = !settable || (props.editing && !input.editable);
  return (
    <Form.Group className="mb-3">
      <Form.Label>
        {input.label} <FormError error={props.errors[fieldId] as any} />
      </Form.Label>
      {input.type === 'select' ? (
        <Form.Select {...props.register(fieldId)} disabled={disable}>
          {input.options.map((o) => (
            <option value={o.value} key={o.value}>
              {o.label}
            </option>
          ))}
        </Form.Select>
      ) : (
        <Form.Control
          type={input.type}
          {...props.register(fieldId, input.validationOptions)}
          disabled={disable}
        />
      )}
    </Form.Group>
  );
};

export default FormGroup;
