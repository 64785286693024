/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment FacilityFields on Facility {\n    id\n    name\n  }\n": types.FacilityFieldsFragmentDoc,
    "\n  query GetFacilities($after: String, $first: Int) {\n    getFacilities(after: $after, first: $first) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          ...FacilityFields\n        }\n      }\n    }\n  }\n": types.GetFacilitiesDocument,
    "\n  mutation UpsertFacility($input: UpsertFacilityInput!) {\n    upsertFacility(input: $input) {\n      ...FacilityFields\n    }\n  }\n": types.UpsertFacilityDocument,
    "\n  mutation DeleteFacility($id: String!) {\n    deleteFacility(id: $id)\n  }\n": types.DeleteFacilityDocument,
    "\nquery GetRepresentativeFacilityRequests($first: Int, $after: String) {\n    getRepresentativeFacilityRequests(first: $first, after: $after) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          person {\n            ...PersonFields\n          }\n          facility {\n            ...FacilityFields\n          }\n          status\n        }\n      }\n    }\n  }": types.GetRepresentativeFacilityRequestsDocument,
    "\n  mutation RespondToRepresentativeFacilityRequest($input: RepresentativeFacilityResponseInput!) {\n    respondToRepresentativeFacilityRequest(input: $input) {\n      person {\n        ...PersonFields\n      }\n      facility {\n        ...FacilityFields\n      }\n      status\n    }\n  }": types.RespondToRepresentativeFacilityRequestDocument,
    "\nfragment PersonFields on Person {\n  id\n  email\n  personType\n}": types.PersonFieldsFragmentDoc,
    "\nquery GetPeople($after: String, $first: Int) {\n  getPeople(after: $after, first: $first) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        ...PersonFields\n      }\n    }\n  }\n}": types.GetPeopleDocument,
    "\nmutation CreateRoomsPerson($input: CreateRoomsPersonInput!) {\n  createRoomsPerson(input: $input) {\n    ...PersonFields\n  }\n}": types.CreateRoomsPersonDocument,
    "\nquery GetPersonFacilities($personId: String!, $first: Int, $after: String) {\n  getPerson(personId: $personId) {\n    ...PersonFields\n    facilities(first: $first, after: $after) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          ...FacilityFields\n        }\n      }\n    }\n  }\n}": types.GetPersonFacilitiesDocument,
    "\nmutation UpdatePersonFacility($personId: String!, $facilityId: String!, $action: ActionEnum!) {\n  updatePersonFacility(personId: $personId, facilityId: $facilityId, action: $action)\n}": types.UpdatePersonFacilityDocument,
    "\nmutation DeletePerson($id: String!) {\n    deletePerson(id: $id)\n}": types.DeletePersonDocument,
    "\nfragment RoomFields on Room {\n  id\n  name\n}": types.RoomFieldsFragmentDoc,
    "\nquery GetFacilityRooms($id: String!, $first: Int, $after: String) {\n  getFacility(id: $id) {\n    ...FacilityFields\n\n    rooms(first: $first, after: $after) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          ...RoomFields\n        }\n      }\n    }\n  }\n}": types.GetFacilityRoomsDocument,
    "\nquery GetRooms($after: String, $first: Int, $facilityId: String!) {\n  getRooms(after: $after, first: $first, facilityId: $facilityId) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        ...RoomFields\n      }\n    }\n  }\n}": types.GetRoomsDocument,
    "\nmutation UpsertRoom($input: UpsertRoomInput!) {\n  upsertRoom(input: $input) {\n    ...RoomFields\n  }\n}": types.UpsertRoomDocument,
    "\nmutation DeleteRoom($id: String!) {\n  deleteRoom(id: $id)\n}\n": types.DeleteRoomDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FacilityFields on Facility {\n    id\n    name\n  }\n"): (typeof documents)["\n  fragment FacilityFields on Facility {\n    id\n    name\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFacilities($after: String, $first: Int) {\n    getFacilities(after: $after, first: $first) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          ...FacilityFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetFacilities($after: String, $first: Int) {\n    getFacilities(after: $after, first: $first) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          ...FacilityFields\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpsertFacility($input: UpsertFacilityInput!) {\n    upsertFacility(input: $input) {\n      ...FacilityFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpsertFacility($input: UpsertFacilityInput!) {\n    upsertFacility(input: $input) {\n      ...FacilityFields\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteFacility($id: String!) {\n    deleteFacility(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteFacility($id: String!) {\n    deleteFacility(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetRepresentativeFacilityRequests($first: Int, $after: String) {\n    getRepresentativeFacilityRequests(first: $first, after: $after) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          person {\n            ...PersonFields\n          }\n          facility {\n            ...FacilityFields\n          }\n          status\n        }\n      }\n    }\n  }"): (typeof documents)["\nquery GetRepresentativeFacilityRequests($first: Int, $after: String) {\n    getRepresentativeFacilityRequests(first: $first, after: $after) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          person {\n            ...PersonFields\n          }\n          facility {\n            ...FacilityFields\n          }\n          status\n        }\n      }\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RespondToRepresentativeFacilityRequest($input: RepresentativeFacilityResponseInput!) {\n    respondToRepresentativeFacilityRequest(input: $input) {\n      person {\n        ...PersonFields\n      }\n      facility {\n        ...FacilityFields\n      }\n      status\n    }\n  }"): (typeof documents)["\n  mutation RespondToRepresentativeFacilityRequest($input: RepresentativeFacilityResponseInput!) {\n    respondToRepresentativeFacilityRequest(input: $input) {\n      person {\n        ...PersonFields\n      }\n      facility {\n        ...FacilityFields\n      }\n      status\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment PersonFields on Person {\n  id\n  email\n  personType\n}"): (typeof documents)["\nfragment PersonFields on Person {\n  id\n  email\n  personType\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetPeople($after: String, $first: Int) {\n  getPeople(after: $after, first: $first) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        ...PersonFields\n      }\n    }\n  }\n}"): (typeof documents)["\nquery GetPeople($after: String, $first: Int) {\n  getPeople(after: $after, first: $first) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        ...PersonFields\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CreateRoomsPerson($input: CreateRoomsPersonInput!) {\n  createRoomsPerson(input: $input) {\n    ...PersonFields\n  }\n}"): (typeof documents)["\nmutation CreateRoomsPerson($input: CreateRoomsPersonInput!) {\n  createRoomsPerson(input: $input) {\n    ...PersonFields\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetPersonFacilities($personId: String!, $first: Int, $after: String) {\n  getPerson(personId: $personId) {\n    ...PersonFields\n    facilities(first: $first, after: $after) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          ...FacilityFields\n        }\n      }\n    }\n  }\n}"): (typeof documents)["\nquery GetPersonFacilities($personId: String!, $first: Int, $after: String) {\n  getPerson(personId: $personId) {\n    ...PersonFields\n    facilities(first: $first, after: $after) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          ...FacilityFields\n        }\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdatePersonFacility($personId: String!, $facilityId: String!, $action: ActionEnum!) {\n  updatePersonFacility(personId: $personId, facilityId: $facilityId, action: $action)\n}"): (typeof documents)["\nmutation UpdatePersonFacility($personId: String!, $facilityId: String!, $action: ActionEnum!) {\n  updatePersonFacility(personId: $personId, facilityId: $facilityId, action: $action)\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DeletePerson($id: String!) {\n    deletePerson(id: $id)\n}"): (typeof documents)["\nmutation DeletePerson($id: String!) {\n    deletePerson(id: $id)\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment RoomFields on Room {\n  id\n  name\n}"): (typeof documents)["\nfragment RoomFields on Room {\n  id\n  name\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetFacilityRooms($id: String!, $first: Int, $after: String) {\n  getFacility(id: $id) {\n    ...FacilityFields\n\n    rooms(first: $first, after: $after) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          ...RoomFields\n        }\n      }\n    }\n  }\n}"): (typeof documents)["\nquery GetFacilityRooms($id: String!, $first: Int, $after: String) {\n  getFacility(id: $id) {\n    ...FacilityFields\n\n    rooms(first: $first, after: $after) {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        cursor\n        node {\n          ...RoomFields\n        }\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery GetRooms($after: String, $first: Int, $facilityId: String!) {\n  getRooms(after: $after, first: $first, facilityId: $facilityId) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        ...RoomFields\n      }\n    }\n  }\n}"): (typeof documents)["\nquery GetRooms($after: String, $first: Int, $facilityId: String!) {\n  getRooms(after: $after, first: $first, facilityId: $facilityId) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        ...RoomFields\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpsertRoom($input: UpsertRoomInput!) {\n  upsertRoom(input: $input) {\n    ...RoomFields\n  }\n}"): (typeof documents)["\nmutation UpsertRoom($input: UpsertRoomInput!) {\n  upsertRoom(input: $input) {\n    ...RoomFields\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DeleteRoom($id: String!) {\n  deleteRoom(id: $id)\n}\n"): (typeof documents)["\nmutation DeleteRoom($id: String!) {\n  deleteRoom(id: $id)\n}\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;