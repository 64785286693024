import { useMutation, useQuery } from '@apollo/client';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { DELETE_ROOM, GET_FACILITY_ROOMS, UPSERT_ROOM } from '../rooms/queries';
import { Facility, RoomFieldsFragment } from '../../__generated__/graphql';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

import { ArrowClockwise, ChevronDoubleDown } from 'react-bootstrap-icons';
import UpsertRoomButton from '../rooms/UpsertRoomButton';
import DeleteButton from '../../components/DeleteButton';

const FacilityDetails: FunctionComponent = () => {
  const { facilityId } = useParams<{ facilityId: string }>();
  const { data, loading, refetch, fetchMore } = useQuery(GET_FACILITY_ROOMS, {
    variables: { id: facilityId! },
  });
  const [upsertRoom] = useMutation(UPSERT_ROOM);
  const [deleteRoom] = useMutation(DELETE_ROOM);

  const handleLoadMore = () => {
    if (data)
      fetchMore({
        variables: { after: data.getFacility.rooms.pageInfo.endCursor },
      });
  };

  if (loading) return <h1>Loading...</h1>;
  if (!data) return <h1>No data</h1>;

  const facility = data.getFacility as Facility;
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          {facility.name} Rooms <Badge pill>{facility.rooms.totalCount}</Badge>{' '}
          <ButtonGroup size="sm">
            <Button onClick={() => refetch()} variant="outline-secondary">
              <ArrowClockwise />
            </Button>
            <UpsertRoomButton
              handleSave={(data) =>
                upsertRoom({
                  variables: { input: { ...data, facilityId: facility.id } },
                }).then(() => refetch())
              }
            />
          </ButtonGroup>
        </Card.Title>

        <Table hover size="sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {facility.rooms.edges.map((e) => {
              const node = e.node as RoomFieldsFragment;
              return (
                <tr key={node.id}>
                  <td>{node.id}</td>
                  <td>{node.name}</td>
                  <td>
                    <ButtonGroup size="sm">
                      <UpsertRoomButton
                        handleSave={(data) =>
                          upsertRoom({ variables: { input: data } }).then(() =>
                            refetch(),
                          )
                        }
                        editFields={node}
                      />
                      <DeleteButton
                        buttonSize="sm"
                        handleDelete={() =>
                          deleteRoom({ variables: { id: node.id } }).then(() =>
                            refetch(),
                          )
                        }
                      />
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button
          variant="outline-secondary"
          disabled={!data.getFacility.rooms.pageInfo.hasNextPage}
          onClick={handleLoadMore}
        >
          Load More <ChevronDoubleDown />
        </Button>
      </Card.Body>
    </Card>
  );
};

export default FacilityDetails;
