import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Amplify } from 'aws-amplify';
import { setContext } from '@apollo/client/link/context';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId:
        process.env.REACT_APP_COGNITO_USER_POOL_ID || 'us-east-1_c7uPWfA6t',
      userPoolClientId:
        process.env.REACT_APP_COGNITO_USER_CLIENT_ID ||
        '5rgt5n7h6m6n8t434e8prfp20l',
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  const session = await fetchAuthSession();
  const token = session.tokens?.accessToken;
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL + '/graphql'
    : 'http://localhost:3010/graphql',
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getFacilities: relayStylePagination(),
          getPeople: relayStylePagination(),
        },
      },
      Facility: {
        fields: {
          rooms: relayStylePagination(),
        },
      },
    },
  }),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
