import { FunctionComponent } from 'react';
import ModalForm, { HandleSave } from '../../components/ModalForm';
import { Pencil, Plus } from 'react-bootstrap-icons';

interface FormInput {
  id: string;
  email: string;
}

const UpsertPersonButton: FunctionComponent<{
  handleSave: HandleSave<FormInput>;
  editFields?: FormInput;
}> = (props) =>
  ModalForm<FormInput>({
    buttonLabel: <Plus />,
    editButtonLabel: <Pencil />,
    inputs: {
      id: {
        type: 'text',
        label: 'ID',
        editable: false,
        settable: false,
      },
      email: {
        type: 'text',
        label: 'Email',
        validationOptions: { required: true },
        editable: false,
      },
    },
    ...props,
    buttonSize: 'sm',
  });

export default UpsertPersonButton;
